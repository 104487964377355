// Fontawesome
// $fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/brands";
@import "@fortawesome/fontawesome-pro/scss/duotone";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/thin";
@import "@fortawesome/fontawesome-pro/scss/light";

// Font - Rajdhani

@font-face {
  font-family: "Rajdhani Regular";
  src: url("../assets/font/Rajdhani-Regular.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

@font-face {
  font-family: "Rajdhani Light";
  src: url("../assets/font/Rajdhani-Light.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

@font-face {
  font-family: "Rajdhani Medium";
  src: url("../assets/font/Rajdhani-Medium.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

@font-face {
  font-family: "Rajdhani Semibold";
  src: url("../assets/font/Rajdhani-SemiBold.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

@font-face {
  font-family: "Rajdhani Bold";
  src: url("../assets/font/Rajdhani-Bold.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

// Inter-Bold font weight 700
@font-face {
  font-family: "Inter Bold";
  src: url("../assets/font/Inter-Bold.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

// Inter-Light font weight 300
@font-face {
  font-family: "Inter Light";
  src: url("../assets/font/Inter-Light.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

// Inter-Regular font weight 400

@font-face {
  font-family: "Inter Regular";
  src: url("../assets/font/Inter-Regular.woff2") format("woff2"); /* Most optimized */
  font-display: swap; /* Improves page load performance */
}

// Inter-Extrabold font weight 800
@font-face {
  font-family: "Inter Extrabold";
  src: url("../assets/font/Inter_18pt-ExtraBold.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

// Inter-Semibold font weight 600
@font-face {
  font-family: "Inter Semibold";
  src: url("../assets/font/Inter-SemiBold.woff2") format("woff2");
  font-display: swap; /* Improves page load performance */
}

// Font - Source Sans Pro
// Source Sans Pro - Regular
@font-face {
  font-family: "Source Sans Pro Regular";
  src: url("../assets/font/SourceSansPro-Regular.woff2") format("woff2");
}
// Source Sans Pro - Light
@font-face {
  font-family: "Source Sans Pro Light";
  src: url("../assets/font/SourceSansPro-Light.woff2") format("woff2");
}
// Source Sans Pro - Semibold
@font-face {
  font-family: "Source Sans Pro Semibold";
  src: url("../assets/font/SourceSansPro-Semibold.woff2") format("woff2");
}
// Source Sans Pro - Bold
@font-face {
  font-family: "Source Sans Pro Bold";
  src: url("../assets/font/SourceSansPro-Bold.woff2") format("woff2");
}

// Public Sans font weight 400
@font-face {
  font-family: "Public Sans Regular";
  src: url("../assets/font/PublicSans-Regular.woff2") format("woff2");
}
