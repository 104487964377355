// TODO: Determine if production app will have uncached thumbnail loads as well.
// I think a side effect of the dev environment's proxy setup causes the thumbnails to load on each hover.
// In reality, there should be no network request for even the first hover, because
// the thumbnail URL in the popover is the same as the thumbnail URL in the small preview next to each result.

// The initial max-width for the ngx-bootstrap popover component is too small.
.result-thumbnail-popover-container {
  max-width: none;
  transition: opacity 225ms;
  will-change: opacity;
  opacity: 1;
  z-index: 400;
  background-color: #fff;
}

// Used to animate the popover.
.result-thumbnail-popover-container.faded-out {
  opacity: 0;

  // Prevents a freshly hidden popover that's in the process of fading out from
  // blocking the hover activation for another nearby result's thumbnail.
  z-index: -9999;
}
