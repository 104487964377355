// Overriding Bootstrap variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1400px,
  xl: 1650px,
);

$container-max-widths: (
  sm: 540px,
  md: 960px,
  lg: 1360px,
  xl: 1610px,
);

@import "@angular/cdk/overlay-prebuilt";
@import "bootstrap/scss/bootstrap";
@import "./font.config.scss";
@import "./colors.config.scss";
@import "./functions";
@import "./partials";
@import "vis-timeline/dist/vis-timeline-graph2d.min";
