$secondary-color: #000;
$action-menu-breakpoint: sm;
$redesign-black: #061436;

// EVENT BASED LANDING PAGES CONFIGS
$event-based-background-sde: "../../assets/img/Background/landing-page-background.png";
// Default SDE Background image -  "../../assets/img/Background/landing-page-background.png";

$event-based-font-color-sde: #001526;
// Default SDE text font color - #001526

$event-based-read-more-font-color: #033d8b;
// Default SDE text Read More font color - #033d8b

$event-based-card-background: #000;
// Default SDE card background color - #000

// Go to header.component.html and change help icon on homepage from src="assets/img/help-white-bg.svg" to src="assets/img/help.svg"
// Go to home.component.html and change the show more icon from src="assets/img/show-more-highlighted.svg" to  src="assets/img/show-more-earth-day.svg"
// Go to home.component.html and change the redirection link as per the highlight
// Very very important - Update the  <meta property="og:image" content="assets/img/Background/SDE-Preview-Default.png"/> to show the preview of SDE landing page as per events

.event-based-landing-page-bg-sde {
  background-image: url($event-based-background-sde);
}

.event-based-font-color-sde-landing-page {
  color: $event-based-font-color-sde !important;
}

.event-based-read-more-font-color-sde-landing-page {
  color: $event-based-read-more-font-color;
}

.event-based-landing-page-card-bg-and-font-color {
  background-color: $event-based-card-background;
  color: #000 !important;
}

// Font Classes
.font-family-rajdhani-regular {
  font-family: "Rajdhani Regular" !important;
}

.font-family-rajdhani-light {
  font-family: "Rajdhani Light" !important;
}

.font-family-rajdhani-bold {
  font-family: "Rajdhani Bold" !important;
}

.font-family-rajdhani-medium {
  font-family: "Rajdhani Medium" !important;
}

.font-family-rajdhani-semibold {
  font-family: "Rajdhani Semibold" !important;
}

.font-family-source-sans-pro-light {
  font-family: "Source Sans Pro Light" !important;
}

.font-family-source-sans-pro-regular {
  font-family: "Source Sans Pro Regular" !important;
}

.font-family-source-sans-pro-semibold {
  font-family: "Source Sans Pro Semibold" !important;
}

.font-family-source-sans-pro-bold {
  font-family: "Source Sans Pro Bold" !important;
}

// Inter font weight 700
.font-family-inter-bold {
  font-family: "Inter Bold" !important;
}
// Inter font weight 300
.font-family-inter-light {
  font-family: "Inter Light" !important;
}

// Inter font weight 800
.font-family-inter-extrabold {
  font-family: "Inter Extrabold" !important;
}

// Inter font weight 400
.font-family-inter-regular {
  font-family: "Inter Regular" !important;
}
// Inter font weight 600
.font-family-inter-semi-bold {
  font-family: "Inter Semibold" !important;
}
// Public Sans font weight 400
.font-family-public-sans-regular {
  font-family: "Public Sans Regular" !important;
}
// CDK drag & drop
.cdk-drag-preview {
  z-index: 2000 !important;
}

.ai-assisted-search-button-enabled-home {
  background: #0b3d91;
  border: 2px solid;
  color: #fff;
  padding: 12px;
  border-radius: 0em !important;
  font-size: 24px;
  letter-spacing: 0.5px;
}

.ai-assisted-search-button-disabled-home {
  background: #fff;
  border: 2px solid #001526;
  color: #000000;
  padding: 12px;
  border-radius: 0em !important;
  font-size: 24px;
  letter-spacing: 0.5px;
}

.ai-assisted-search-button-enabled-results {
  background: #0b3d91;
  border: 2px solid;
  color: #fff;
  padding: 7px;
  border-radius: 0em !important;
  font-size: 20.4px;
  letter-spacing: 0.5px;
}

.ai-assisted-search-button-disabled-results {
  background: #fff;
  border: 2px solid;
  color: #000;
  padding: 7px;
  border-radius: 0em !important;
  font-size: 20.4px;
  letter-spacing: 0.5px;
}

.mdc-switch .mdc-switch__handle::before {
  background: none !important;
}
.mdc-switch:enabled .mdc-switch__track::after {
  background: white !important;
}

::ng-deep .mdc-switch[aria-checked="true"] .mdc-switch__shadow {
  background: #0b3d91 !important;
  border: 1px solid white !important;
}

::ng-deep .mdc-switch[aria-checked="false"] .mdc-switch__shadow {
  background: #4d4b4b !important;
}

.mdc-switch--unselected .mdc-switch__icon--off {
  display: none !important;
}

.mdc-switch--selected .mdc-switch__icon--on {
  display: none !important;
}
// Validation
sq-validation-message {
  @extend .invalid-feedback;

  .form-group > .is-invalid ~ & {
    display: block;
  }
}

html {
  height: 100%;
  font-family: "Rajdhani Regular" !important;
}

body {
  height: 100%;
  font-family: "Inter Regular" !important;
}

a {
  text-decoration: none;
  color: #3434d6;
}

h2 {
  font-family: "Source Sans Pro" !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: 0.02em !important;
  padding: 16px 0px !important;
}

.accordion-button:not(.collapsed) {
  //background-color: #d9e6fc !important;
  font-family: "Source Sans Pro" !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  letter-spacing: 0.02em !important;
  color: #061436 !important;
  background-color: white !important;
}

.accordion-button:after {
  display: none !important;
}

.accordion-body {
  padding: 2rem !important;
  font-family: "Source Sans Pro" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 23px !important;
  letter-spacing: 0.02em !important;
  color: #000000 !important;
}

.home {
  min-height: 100vh;
}

.sde-filters-list {
  border-radius: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: space-between;
  background-color: #e7ecf4 !important;
  color: #000 !important;
  border: 1px solid #0b3d91;
  font-size: 20.4px;
  padding: 0.35rem 0.5rem;
  margin: 0.5vh 1vw !important;
}

// large screens (> sm)
@include media-breakpoint-up(sm) {
  .sq-home-facet-bar {
    min-height: 287px;
  }

  .card {
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.08);
  }
}

@include media-breakpoint-down(sm) {
  app-search-form {
    width: rem-calc(520) !important;
  }

  main.help-page img {
    width: -webkit-fill-available;
  }
}

@include media-breakpoint-down(xs) {
  .worm-logo-container {
    width: 19.5rem !important;

    // Hard-coded to prevent the search bar from jumping around as the image loads in
    // Determined by hovering over the worm logo container <div> tag in the Chrome inspector and converting the px to rem with rem-calc
    height: 5.88671875rem !important;
  }

  .sde-logo {
    width: 19.4rem !important;

    // Hard-coded to prevent the search bar from jumping around as the image loads in
    // Determined by hovering over the SDE logo <img> tag in the Chrome inspector and converting the px to rem with rem-calc
    height: 2.1245125rem !important;
  }

  app-search-form {
    width: rem-calc(420) !important;
  }
}

app-search-form {
  .autocomplete-item {
    small,
    i {
      color: $secondary-color;
    }
  }
}

app-search nav {
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.08);
}
app-preview {
  app-sde-preview-entity-panel {
    app-sde-facet-card {
      .card {
        border: none;
        border-radius: 0px;
        background: #fafbfd;
        box-shadow: none;
      }
      .card-header {
        background: #fafbfb;
      }
      .card-title {
        font-size: 27px;
        color: #061436 !important;
        margin-left: 5px;
        font-family: "Rajdhani Semibold" !important;
      }

      .btn.btn-light.btn-sm.ng-star-inserted {
        background-color: #fafbfd;
        border: none !important;
      }
      .fa-light.fa-minus {
        font-size: 28px !important;
      }
      .fa-light.fa-plus {
        font-size: 28px !important;
      }
      .fa-sort-alpha-down {
        font-size: 18px !important;
        font-weight: 300;
        color: black;
      }
      .fa-check-square {
        font-size: 18px;
      }
      .fa-square {
        font-size: 18px;
      }
    }
  }
}
app-sde-preview-related-docs-panel {
  margin-left: 8px;
  margin-right: 9px;
  height: 100vh;
}
app-sde-preview-extracts-panel {
  border: 1px solid #023d8b;
  margin: 0 0.2vw 0 0.2vw;
  border-top: transparent !important;
}
app-sde-did-you-mean {
  .sq-did-you-mean.sq-search.sq-text {
    color: #e10000;
    font-size: 23px;
    font-family: "Source Sans Pro Bold" !important;
  }
  .sq-did-you-mean-corrected {
    color: #023d8b;
    margin-right: 0.5vw !important;
    cursor: default;
    font-style: italic;
  }
}
// Results list

sq-tabs {
  .nav-tabs {
    border-bottom: transparent !important;
  }

  .nav-item .count {
    font-size: 0.9em;
    color: $secondary-color;
  }

  .nav-item .nav-link {
    @extend .font-family-rajdhani-semibold;
    font-size: 20px;
    color: #4d5b67;
    border: 1px solid black !important;
    border-radius: 0px !important;

    .count {
      font-size: 20px;
      color: #4d5b67;
    }
  }

  .nav-item .nav-link.active {
    @extend .font-family-rajdhani-bold;
    color: white;
    background-color: #023d8b !important;

    .count {
      color: white;
      font-weight: bold;
    }
  }
}

.tabs-content {
  .nav-item .nav-link {
    @extend .font-family-rajdhani-medium;
    font-size: 28px;
    color: #ffffff;
    border-radius: 0 !important;
  }

  .nav-item .nav-link.active {
    @extend .font-family-rajdhani-bold;
    font-size: 28px;
    color: #ffffff;
    background-color: transparent;
  }

  .nav-tabs {
    border-bottom: none !important;
  }

  .nav-tabs .nav-link:hover {
    border-color: transparent;

    &:focus {
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }

    &:focus-visible {
      border-color: transparent;
    }
  }
}

.tabs-content-messengers {
  .nav-item .nav-link.active {
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    background: #335c674d;

    &:hover {
      border-color: #335c67;
    }
  }
}

.tabs-content-objects {
  .nav-item .nav-link.active {
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    background: #9e2a2b4d;

    &:hover {
      border-color: #9e2a2b;
    }
  }
}

.tabs-content-signals {
  .nav-item .nav-link.active {
    border-width: 2px 2px 0px 2px;
    border-style: solid;
    background: #5033674d;

    &:hover {
      border-color: #503367;
    }
  }
}

.helio-tab-container {
  .nav-item .nav-link {
    padding: 13px 50px !important;
    font-size: 30px !important;
  }
  .nav-item .nav-link.active {
    padding: 13px 50px !important;
    font-size: 30px !important;
  }
}

//nav links tabs
.navbar-sde-tabs {
  .nav-tabs {
    border-bottom: 1px solid #000000 !important;
  }

  .nav-item .count {
    font-size: 0.9em;
    color: $secondary-color;
  }

  .nav-item .nav-link {
    @extend .font-family-rajdhani-semibold;
    font-size: 20px;
    color: #4d5b67;
    border: none !important;
    border-radius: 0px !important;

    .count {
      font-size: 20px;
      color: #4d5b67;
    }
  }

  .nav-item .nav-link.active {
    @extend .font-family-rajdhani-bold;
    color: #0b3d91 !important;
    border-bottom: 4px solid #0b3d91 !important;

    .count {
      color: #0b3d91;
      font-weight: bold;
    }
  }
}

sq-results-counter {
  color: $secondary-color;
  font-size: 0.85em;
  padding: 0.375rem 0;
}

sq-result-selector label {
  margin: 0 !important;
  margin-left: 4px;
}

sq-result-extracts {
  .extracts-text {
    word-break: break-word !important;
    color: #4d5b67 !important;
    font-weight: 400 !important;
    font-size: 18px !important;
  }
}

app-sde-result-title {
  .record .match-highlight {
    font-weight: bold;
    font-style: italic;
  }
}

app-sde-facet-card {
  .messenger-bg {
    .btn.btn-light.btn-sm {
      background: #c4d0d9 !important;
      border-color: #c4d0d9 !important;
    }
  }

  .object-bg {
    .btn.btn-light.btn-sm {
      background: #c9c0cb !important;
      border-color: #c9c0cb !important;
    }
  }

  .signal-bg {
    .btn.btn-light.btn-sm {
      background: #c9c7d9 !important;
      border-color: #c9c7d9 !important;
    }
  }
}

app-sde-facet-list {
  .list-group,
  .list-group-item {
    background-color: #e6ecf4 !important;
  }
}

.active-tab {
  @extend .font-family-rajdhani-bold;
  color: #0b3d91 !important;
  border-bottom: 4px solid #0b3d91 !important;

  .count {
    color: #0b3d91 !important;
    font-weight: bold !important;
  }
}

app-sde-facet-list {
  .messenger-bg-ul {
    .list-group,
    .list-group-item {
      background: #c4d0d9 !important;
    }
  }

  .object-bg-ul {
    .list-group,
    .list-group-item {
      background: #c9c0cb !important;
    }
  }

  .signal-bg-ul {
    .list-group,
    .list-group-item {
      background: #c9c7d9 !important;
    }
  }
}

.sq-relevant-extracts {
  .extracts-text {
    @extend .font-family-public-sans-regular;
    font-size: 21px;
  }
}

.sq-matching-passage {
  max-height: fit-content !important;

  .extracts-text {
    @extend .font-family-public-sans-regular;
    font-size: 21px;
  }
}

//title color

.record .sq-result-title {
  @extend .font-family-rajdhani-bold;
  font-size: 27px;
  color: #023d8b;
  letter-spacing: normal;
}

.record app-sde-result-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected .sq-result-title {
  @extend .font-family-rajdhani-bold;
  font-size: 30px;
  color: #023d8b !important;
  letter-spacing: normal !important;
}

.not-selected .sq-result-title {
  @extend .font-family-rajdhani-bold;
  font-size: 30px;
  color: #4d5b67 !important;
  letter-spacing: normal !important;
}

//source path
.record .sq-result-source span {
  color: #99231b;
}

//color icon types
.iconPdf {
  color: #f40f02;
}

.iconDocx {
  color: #2b579a;
}

.iconExcel {
  color: #217346;
}

.iconPwt {
  color: #d24726;
}

//css for modified date and user
.record .modified {
  font-size: 0.9rem;
}

sq-result-thumbnail img {
  max-width: 100px;
  max-height: 150px;
  border-radius: 3px;
}

sq-sponsored-results {
  .sponsored-item {
    margin-top: 0.75em;
  }

  .sq-sponsored-link-view-summary {
    color: $secondary-color;
    font-size: 0.9em;
  }
}

// Facets
sq-facet-card.facet-preview {
  .card-header {
    .sq-metadata {
      color: $secondary-color;
      font-size: 0.9em;
    }
  }
}

app-sde-facet-mysearch {
  max-width: 74% !important;
  border-left: 1px solid #000 !important ;
}

sq-feedback-menu {
  button.btn {
    border-radius: 30px;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

// Flex basis classes
.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-auto {
  flex-basis: auto;
}

.sq-metadata-item-value {
  margin-right: 5px !important;
  font-weight: 400 !important;
  border-radius: 0.25rem !important;
}

a.sq-metadata-item-collection {
  background-color: #104080 !important;
  color: #f5f5f5 !important;
}

a.sq-metadata-item-authors {
  background-color: #ffa07a !important;
}

a.sq-metadata-item-docformat {
  background-color: #ffb6c1 !important;
}

a.sq-metadata-item-size {
  background-color: #406fad !important;
  color: #f5f5f5 !important;
}

// Document Preview

app-preview {
  height: 100%;
  // padding-left: 0.95rem;
}

// Pager - modify the contrast to be ADA complaint

sq-pager {
  .page-item .page-link {
    background-color: white;
    color: #333a45;
  }

  .page-item.disabled .page-link {
    background-color: white;
    color: #333a45;
  }

  .page-item.active .page-link {
    background-color: #333a45;
    color: white;
    border-color: grey;
  }

  .page-link:hover {
    background-color: #333a45;
    color: #f5f5f5;
  }
}

// Facet-preview
sq-facet-card.facet-preview > div {
  position: absolute;
  width: 100%;
}

sq-facet-card.facet-preview > div > div {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
}

@import "@angular/cdk/overlay-prebuilt";

// Overriding Bootstrap variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1400px,
  xl: 1650px,
);

$container-max-widths: (
  sm: 540px,
  md: 960px,
  lg: 1360px,
  xl: 1610px,
);

// Bootstrap styles
@import "bootstrap/scss/bootstrap";

// Fontawesome
/* $fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid"; */

// CDK drag & drop
.cdk-drag-preview {
  z-index: 2000 !important;
}

// Validation
sq-validation-message {
  @extend .invalid-feedback;

  .form-group > .is-invalid ~ & {
    display: block;
  }
}

// File formats & metadata icons
@import "icons";

// Preview stylings (incl. entity highlights & metadata)
@import "../preview";

// Sinequa components stylesheets
$action-menu-breakpoint: sm;
@import "../../../../components/action/bootstrap/action.scss";
@import "../../../../components/notification/bootstrap/notification.scss";
@import "../../../../components/metadata/metadata.scss";
@import "../../../../components/advanced/bootstrap/advanced.scss";

$secondary-color: #6c757d;

html {
  height: 100%;
}

body {
  height: 100%;
}

a {
  text-decoration: none;
  color: #3434d6;
}

.home {
  min-height: 100vh;
}

// large screens (> sm)
@include media-breakpoint-up(sm) {
  .sq-home-facet-bar {
    min-height: 287px;
  }

  .card {
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.08);
  }
}

app-search-form {
  .autocomplete-item {
    small,
    i {
      color: $secondary-color;
    }
  }
}

app-search nav {
  box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.08);
}

// Results list

sq-tabs {
  .nav-item .count {
    font-size: 0.9em;
    color: $secondary-color;
  }
}

sq-results-counter {
  color: $secondary-color;
  font-size: 0.85em;
}

sq-sort-selector ul {
  background-color: #f2f2f2 !important;
  border: 1px solid #7598d2 !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;

  & > li {
    & > a {
      & > sq-action-item-content {
        & > div {
          font-weight: 400;
          font-size: 0.95em;
          color: $redesign-black;
        }
      }
    }
  }
}

sq-sort-selector button.btn {
  height: inherit;
  color: $redesign-black;
  background: transparent;
  font-size: 1.3em;
  letter-spacing: 1px;
  border: none;
  font-weight: 700;

  & > div {
    padding: 5px 0;
  }

  &.btn-light:hover {
    background: transparent !important;
    color: $redesign-black !important;
  }

  &.btn-light:focus {
    background: transparent !important;
    color: $redesign-black !important;
    box-shadow: none !important;
  }

  &.show > .btn-light.dropdown-toggle:focus {
    background: transparent !important;
    color: $redesign-black !important;
    box-shadow: none !important;
  }
}

sq-result-selector label {
  margin: 0 !important;
  margin-left: 4px;
}

app-sde-result-title {
  .record .match-highlight {
    font-weight: bold;
    font-style: italic;
  }
}

sq-result-thumbnail img {
  max-width: 100px;
  max-height: 150px;
  border-radius: 3px;
}

sq-sponsored-results {
  .sponsored-item {
    margin-top: 0.75em;
  }

  .sq-sponsored-link-view-summary {
    color: $secondary-color;
    font-size: 0.9em;
  }
}

// Facets
sq-facet-card.facet-preview {
  .card-header {
    .sq-metadata {
      color: $secondary-color;
      font-size: 0.9em;
    }
  }
}

sq-feedback-menu {
  button.btn {
    border-radius: 19px;
    width: 38px;
    height: 38px;
    padding-left: 10px;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

sq-facet-tree {
  .list-group.list-group-flush.scrollable {
    max-height: calc(75vh - 250px);
  }
}

.metadata-tabs-box .nav-tabs {
  margin-left: 1.5rem !important;
  margin-right: 1.9rem !important;
  border-bottom: 1px solid black !important;

  .nav-link {
    background: white !important;
    text-align: center !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px 5px 0px 0px;
  }

  .nav-link.active {
    box-shadow: 0 -2.5px 6px -2px rgba(0, 0, 0, 0.25) !important;
    position: relative;
    z-index: 4;
    background-color: #0b3d91 !important;
    color: #fff !important;
  }
}

.no-data-text-container {
  width: 100%;
  text-align: center;
  height: 4vh;
}

.cursor-notAllowed {
  cursor: not-allowed;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

.back-to-home {
  @extend .font-family-rajdhani-semibold;
  align-items: center;
  display: flex;
  cursor: pointer !important;
  width: fit-content;
  height: 41px;
  color: #033d8b;
  font-size: clamp(22px, 1.2vw, 30px);
  border: 2px solid #033d8b;
  background-color: #f1f7fe;
  padding: 22px 18px;
  margin-bottom: 5px;

  span {
    margin: 0 0.5em;
  }
}

#large-preview {
  .dropdown-toggle {
    color: #061436 !important;
    background: #f2f2f2 !important;
    border: 1px solid #7598d2 !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
    font-family: "Roboto" !important;
    font-weight: 700 !important;
    border-radius: 32px !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .dropdown-item {
    color: #061436 !important;
    font-family: "Roboto" !important;
  }

  .dropdown-three-dots-button {
    position: relative;
    border: 1px solid #d9e6fc !important;
    background-color: white !important;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15) !important;
    border-radius: 100% !important;
    height: 3vh !important;
    width: 3vh !important;
    margin: 0 0.3vw !important;
  }

  .record-name {
    font-size: 1.7em !important;
  }

  .nav-link {
    font-weight: normal;
    cursor: pointer;
  }

  .nav-link:hover {
    font-weight: normal;
  }

  .active {
    font-weight: bold;
    color: #061436;
  }

  .active:hover {
    font-weight: bold;
  }
}

.arrow-navigate {
  cursor: pointer;
  position: relative;
  margin-left: auto;
  align-self: center;
  border: 1px solid black;
  height: 3vh;
  width: 3vh;
  border-radius: 50%;
  border: 0.5px solid #7598d2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

// Flex basis classes
.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-auto {
  flex-basis: auto;
}

@import "dark-mode";

/* hide scrollbar but allow scrolling */
html,
body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.fas {
  font-weight: 100;
}

.highlight {
  background-color: #7598d2;
  // border: 1px solid black;
  font-weight: 500;
}

app-sde-facet-card {
  .card-title {
    font-size: clamp(24px, 1.4vw, 27px);
    color: #061436 !important;
    margin-left: 5px;
  }
  .btn.btn-light.btn-sm {
    background-color: #e6ecf4;
    border-color: #e6ecf4;
  }
  .fa-light.fa-plus.fa-sharp {
    font-size: 40px !important;
  }
  .fa-light.fa-minus {
    font-size: 36px !important;
  }
  .fa-search.fas {
    font-size: 23px !important;
  }
  .fa-filter.fas {
    font-size: 23px !important;
  }
  .fa-filter-slash.fas {
    font-size: 23px !important;
  }

  .card {
    box-shadow: unset !important;
  }
  .card-header {
    border-bottom: none !important;
  }
}

.header-row {
  margin-top: 5px;
}
sq-preview-search-form {
  .btn.btn-primary {
    display: none !important;
  }
}

app-sde-preview-extracts-panel {
  .card {
    box-shadow: none !important;
    border: 1px solid #023d8b !important;
    border-radius: 0px !important;
  }

  .card-body {
    font-size: 18px !important;
    color: #4d5b67 !important;
  }
}

.preview-icon {
  cursor: pointer !important;
}

app-sde-preview {
  overflow-y: hidden !important;
  -ms-overflow-style: none;
}

app-sde-preview::-webkit-scrollbar {
  display: none;
}

.biological-and-physical-sciences-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}
app-metadata-overview {
  .uc-link {
    color: #001526 !important;
  }

  .uc-link:hover {
    color: #001526 !important;
    text-decoration: underline !important;
  }
}

.new-line-for-delimiter-items {
  white-space: pre-line;
}

.extracts-text {
  b {
    background-color: #ccd8e8 !important;
  }
}
.joyride-step__holder {
  width: 50% !important;
}

.joyride-step__title {
  font-weight: 600 !important;
  font-size: clamp(26px, 2.5vw, 35px) !important;
  font-family: "Rajdhani Bold" !important;
}
.joyride-button {
  height: unset !important;
  width: unset !important;
}
.joyride-step__buttons-container {
  width: 100% !important;
  justify-content: space-between;
}
.joyride-step__button:last-child {
  margin-left: 2.5px;
  position: absolute;
  bottom: 4% !important;
  right: 2%;
}

.joyride-step__close {
  cursor: pointer !important;
  content: url("../../assets/img/walkthrough-closeicon.svg");
  width: 37px !important;
  height: 37px !important;
  right: 15px !important;
  top: 20px !important;
}
.joyride-button {
  padding: 0px 6px;
  font-size: 23px !important;
  font-family: "Rajdhani Semibold" !important;
}

.modal-dialog:has(.help-and-support-modal) {
  float: right !important;
  top: 9% !important;
  right: 27px;
}

.modal-dialog:has(.applied-filters) {
  right: 20%;
  top: 20%;

  .modal-content {
    width: 65vw !important;
  }
}

.modal-dialog:has(.acronyms-info-container) {
  right: 1%;
  top: 45%;
  .modal-content {
    width: 40vw !important;
  }
}

.modal-dialog:has(.custom-modal) {
  right: 13%;
  top: 20%;

  .modal-content {
    width: 43vw !important;
  }

  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  padding-bottom: 0px;
}

sq-modal .modal-dialog {
  float: unset !important;
  top: unset !important;
}

sq-modal .modal-header {
  padding-bottom: 18px !important;
}

sq-modal .modal-content {
  border-radius: 5px !important;
}

app-contact-us-page app-header {
  .navbar-bg-all,
  .navbar-bg-earth-science,
  .navbar-bg-planetary,
  .navbar-bg-astrophysics,
  .navbar-bg-bio-physical,
  .navbar-bg-heliophysics,
  .navbar-mobile-height {
    height: 110px !important;
    background-image: none !important;
    background-size: cover;
  }
}
app-multi-messenger-astronomy app-header {
  .navbar-bg-all,
  .navbar-bg-earth-science,
  .navbar-bg-planetary,
  .navbar-bg-astrophysics,
  .navbar-bg-bio-physical,
  .navbar-bg-heliophysics,
  .navbar-mobile-height {
    height: 110px !important;
    background-image: none !important;
    background-size: cover;
  }
}
app-content-curation-page app-header {
  .navbar-bg-all,
  .navbar-bg-earth-science,
  .navbar-bg-planetary,
  .navbar-bg-astrophysics,
  .navbar-bg-bio-physical,
  .navbar-bg-heliophysics,
  .navbar-mobile-height {
    height: 110px !important;
    background-image: none !important;
    background-size: cover;
  }
}

#joyride-step-firstStep .joyride-step__header,
#joyride-step-secondStep .joyride-step__header,
#joyride-step-thirdStep.joyride-step__header,
#joyride-step-fourthStep.joyride-step__header,
#joyride-step-fifthStep.joyride-step__header,
#joyride-step-sixthStep .joyride-step__header,
#joyride-step-seventhStep .joyride-step__header,
#joyride-step-ninethStep .joyride-step__header,
#joyride-step-tenthStep .joyride-step__header,
#joyride-step-eleventhStep .joyride-step__header,
#joyride-step-twelveStep .joyride-step__header,
#joyride-step-thirteenStep .joyride-step__header,
#joyride-step-mobileFilterStep .joyride-step__header {
  display: none;
}

.joyride-content {
  @extend .font-family-inter-regular;
  font-size: clamp(17px, 1.6vw, 20px) !important;
}
.joyride-step-header {
  display: flex;
  align-items: center;
  padding: 8px 0px 16px 0px;

  .joyride-step-title {
    color: rgb(2, 61, 139);
    font-weight: 600 !important;
    font-size: clamp(28px, 3vw, 35px) !important;
    font-family: "Rajdhani Bold";
  }
}

#joyride-step-firstStep {
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  margin: auto !important;
  width: fit-content !important;
  height: fit-content !important;

  .joyride-step__arrow {
    display: none !important;
  }
}

#joyride-step-firstStepForTdamm .joyride-step__arrow,
#joyride-step-firstStepForTdammResults .joyride-step__arrow {
  display: none !important;
}

.single-line-ellipsis {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.walkThrough-title {
  color: rgb(2, 61, 139);
}

.sq-tooltip {
  font-family: "Source Sans Pro Regular" !important;
  background-color: #272626 !important;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 14px;
  max-width: 40vw !important;
}

@media only screen and (max-width: 768px) {
  .modal-dialog:has(.help-and-support-modal) {
    float: none !important;
    right: unset !important;
  }

  .modal-dialog:has(.acronyms-info-container) {
    left: 3%;

    .modal-content {
      width: 90vw !important;
    }
  }

  .modal-dialog:has(.custom-modal-mobile) {
    top: 10%;

    .modal-content {
      background-color: transparent !important;
    }

    .modal-header {
      border-bottom-color: transparent !important;
      margin-bottom: 10px;
      justify-content: end;
      padding-right: 0 !important;
    }

    .modal-body {
      background-color: #ffffff !important;
      max-height: 67vh;
      overflow-y: auto;
    }
  }

  .record app-sde-result-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
  }

  ::ng-deep .joyride-step__holder {
    left: 15px !important;
    width: 100% !important;
  }

  .joyride-step__button:last-child {
    bottom: 2.5% !important;
  }
}

.active > .page-link {
  background-color: #023d8b !important;
  color: #fff !important;
  border-color: gray !important;
}
.page-link {
  background-color: #fff !important;
  color: #023d8b !important;
  font-size: 20px !important;
  font-family: "Rajdhani Semibold" !important;
}
.page-item.disabled {
  cursor: not-allowed !important;
}
.pagination {
  justify-content: center !important;
}

.app-sde-result-title {
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ai-assisted-related-docs-title {
  color: #023d8b;
  font-family: "Rajdhani Bold";
  font-size: 26px;
  letter-spacing: 0.5px;
}

.app-sde-result-title {
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.neural-passages {
  box-shadow: 0px 4px 24px 0px #00000040;

  .card-title {
    color: #444447 !important;
    font-family: "Rajdhani Bold" !important;
    font-size: 26px !important;
    letter-spacing: 0.5px !important;
  }

  .card-header {
    background-color: #fff;
    border: none;
  }
}
.neural-passages {
  app-sde-facet-card {
    .card {
      padding-bottom: 1rem !important;

      sq-collapse {
        border-bottom: none !important;
      }
    }
  }
}
sde-top-passages {
  .list-group-item {
    .sde-passage-title {
      app-sde-result-title {
        .sq-result-title {
          color: #023d8b !important;
          font-family: "Rajdhani Bold" !important;
          font-size: 27px;
          margin: 1vh 0 !important;
          letter-spacing: normal;
        }
      }
    }
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff !important;
  border-radius: 10px !important;
}
