sq-metadata-item .sq-metadata-item {

    a.badge {
        background-color: lightgrey;
        color: lighten(black, 10%);

        &:hover, &:focus {
            background-color: darken(lightgrey, 10%);
            color: black;
        }
    }

}
