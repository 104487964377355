// DARK MODE

$dark-background: #000000;
$light-dark-color: #323238;
$white-color: #ffffff;
$event-based-background-sde: "../../assets/img/Background/landing-page-background.png";
$event-based-font-color-sde: #848484;

body.dark {
  .event-based-landing-page-bg-sde {
    background-image: url($event-based-background-sde);
    background-color: $dark-background;
  }

  .event-based-font-color-sde-landing-page {
    color: $event-based-font-color-sde !important;
  }

  .left-filter {
    background-color: $light-dark-color;
  }
  #search-results .card .card-header {
    background-color: $light-dark-color;
  }

  app-sde-facet-card {
    .btn.btn-light.btn-sm {
      background-color: $light-dark-color;
      border-color: $light-dark-color;
    }
  }

  .fa-plus {
    color: $white-color !important;
  }

  .fa-minus {
    color: $white-color !important;
  }

  app-sde-facet-list {
    .list-group,
    .list-group-item {
      background-color: $light-dark-color !important;
    }
  }
}
